<script setup>
import { ref, computed } from 'vue';
import { GlLink, GlDisclosureDropdown, GlAlert, GlBroadcastMessage } from '@gitlab/ui';
import { useUserQuery } from '~/queries/use_user_query';
import { useTenantQuery } from '~/queries/use_tenant_query';
import { usePCLQuery } from '~/queries/use_pcl_query';

import TanukiLogo from '~/layout/navbar/tanuki_logo.vue';
import ApiErrorAlert from '~/common/api_error_alert.vue';

defineOptions({ inheritAttrs: false });

const props = defineProps({
  links: {
    type: Object,
    required: true,
  },
  switchboardVersion: {
    type: String,
    required: true,
  },
  tenant: {
    type: Object,
    required: false,
    default: null,
  },
});

const apiError = ref(null);

const {
  data: user,
  isTenantAdmin,
  isGitlabUser,
  updateNotificationPreferencesAsync,
} = useUserQuery();

const notificationPreferencesUpdated = ref(false);
const toggleUserNotificationPreferences = async () => {
  try {
    await updateNotificationPreferencesAsync(!user.value.notification_preference);

    notificationPreferencesUpdated.value = true;
  } catch (e) {
    apiError.value = e.response.data;
  }
};

const envLabel = computed(() => {
  if (!isGitlabUser.value || !user.value?.env) return '';

  return `(${user.value.env})`;
});

const userMenuItems = computed(() => {
  const topItems = {
    tenant_maintenance: 'Tenant Maintenance',
    operations_schedule: 'Operations Schedule',
    manage_gitlab_users: 'Manage GitLab Users',
    tenants: 'Change Tenants',
    onboarding_tenants: 'Onboarding Tenants',
    access_tokens: 'Access Tokens',
  };

  const topItemLinks = Object.keys(topItems).reduce((acc, item) => {
    const link = props.links[item];
    if (!link) return acc;

    return [...acc, { text: topItems[item], href: link }];
  }, []);

  const switchboardVersion = { text: props.switchboardVersion, extraAttrs: { disabled: true } };
  const toggleNoticationPreferences = {
    text: `Toggle email notifications ${user.value.notification_preference ? 'off' : 'on'}`,
    action: toggleUserNotificationPreferences,
  };

  const logoutLink = { text: 'Logout', href: props.links.logout };

  return [
    {
      items: [...topItemLinks, toggleNoticationPreferences, switchboardVersion],
    },
    { items: [logoutLink] },
  ];
});

const tenantLinks = computed(() => {
  const { configuration, jobs, users, audit_logs, json_override, hosted_runners } = props.links;
  const overviewLink = isGitlabUser.value ? `/tenants/${props.tenant.id}/overview` : undefined;

  const links = [
    { href: overviewLink, title: 'Overview' },
    { href: configuration, title: 'Configuration' },
    { href: `/tenants/${props.tenant.id}/config_change_logs`, title: 'Configuration change log' },
    { href: jobs, title: 'Jobs' },
    { href: users, title: 'Users' },
    { href: audit_logs, title: 'Audit logs' },
    { href: json_override, title: 'JSON override' },
    { href: hosted_runners, title: 'Hosted runners' },
  ];

  return links.filter((link) => link.href);
});

/**
 * TODO: Until navbar is part of the SPA and we have router set up here
 * we have to rely on parsing the URL to determine if we are on the welcome page
 *
 * https://gitlab.com/gitlab-com/gl-infra/gitlab-dedicated/team/-/issues/5930
 */
const WELCOME_PAGE = /^\/tenants\/\d+\/welcome$/;
const isWelcomePage = WELCOME_PAGE.test(window.location.pathname);

/**
 * We have to use a secondary tenant object here since the "tenant" provided
 * by backend through the prop is only a subset of the whole model properties, plus it includes two
 * custom properties that are not in the serialized tenant model:
 * - status
 * - changes_pending
 */
const { data: fullTenant } = useTenantQuery({ tenantId: computed(() => props.tenant?.id) });
const canSeeNav = computed(() => {
  if (!isWelcomePage || !isTenantAdmin.value) return true;

  return fullTenant.value?.ever_deployed ?? true;
});

const { data: pcl } = usePCLQuery();
</script>

<template>
  <gl-broadcast-message
    v-if="pcl"
    icon-name="bullhorn"
    theme="red"
    type="banner"
    data-testid="pcl-alert"
    :dismissible="false"
  >
    <p>
      {{ pcl.description }}
    </p>
  </gl-broadcast-message>

  <nav
    v-bind="$attrs"
    class="navbar navbar-light navbar-expand-lg gl-bg-gray-50 gl-shadow-sm gl-shadow-gray-100"
  >
    <div class="container">
      <a :href="links.home_path" class="navbar-brand gl-flex gl-items-center">
        <div class="nav-logo gl-pr-4">
          <tanuki-logo />
        </div>

        <span data-testid="appTitle">Switchboard {{ envLabel }}</span>
      </a>

      <ul class="nav navbar-nav">
        <li v-if="tenant" class="navbar-text mr-2">{{ tenant.name }}</li>
        <gl-disclosure-dropdown v-if="user" :toggle-text="user.email" :items="userMenuItems" />
      </ul>
    </div>
  </nav>

  <div
    v-if="tenant && canSeeNav"
    class="navbar navbar-light navbar-expand-lg gl-border-b gl-bg-gray-10"
    data-testid="tenant-links"
  >
    <div class="container">
      <ul class="nav navbar-nav">
        <li v-for="link in tenantLinks" :key="link.href">
          <gl-link
            :href="link.href"
            class="nav-link gl-rounded-base !gl-text-gray-700 hover:gl-bg-gray-100 active:gl-text-white"
          >
            {{ link.title }}
          </gl-link>
        </li>
      </ul>
    </div>
  </div>

  <api-error-alert v-if="apiError" :error="apiError" @dismiss="apiError = null" />

  <gl-alert
    v-if="notificationPreferencesUpdated"
    class="gl-my-4"
    title=""
    dismissible
    show-icon
    dismiss-label="Dismiss"
    variant="info"
    :sticky="false"
    @dismiss="notificationPreferencesUpdated = false"
  >
    Your notification preferences have been updated
  </gl-alert>
</template>
