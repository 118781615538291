import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { unref, computed } from 'vue';
import { getTenant } from '~/api/tenants';

export const useTenantQuery = ({ tenantId }) => {
  const queryKey = ['tenant', tenantId];

  const enabled = computed(() => {
    return Boolean(unref(tenantId));
  });

  const client = useQueryClient();

  const query = useQuery({
    queryFn: () => getTenant(unref(tenantId)).then((res) => res.data.data),
    queryKey,
    enabled,
    staleTime: 10000,
  });

  const { data, error, status, isLoading } = query;

  return {
    queryKey,
    data,
    error,
    status,
    isLoading,
    invalidate: () => client.invalidateQueries({ queryKey }),
  };
};
