export const ROLE = {
  GITLAB_OPERATOR: 'gitlab_operator',
  GITLAB_SUPPORT: 'gitlab_support',
  GITLAB_PROVISIONER: 'gitlab_provisioner',
  GITLAB_READONLY: 'gitlab_readonly',
  TENANT_ADMIN: 'regular',
};

export const ROLE_NAMES = {
  [ROLE.GITLAB_READONLY]: 'Readonly',
  [ROLE.GITLAB_SUPPORT]: 'Support',
  [ROLE.GITLAB_PROVISIONER]: 'Provisioner',
  [ROLE.GITLAB_OPERATOR]: 'Operator',
};

export const USER_PERMISSIONS_IN_TENANT = {
  CAN_READ_TENANT: 'can_read_tenant',
  CAN_UPDATE_TENANT_JSON: 'can_update_tenant_json',
  CAN_UPDATE_TENANT: 'can_update_tenant',
  CAN_MANAGE_TENANT_USERS: 'can_manage_tenant_users',
  CAN_CREATE_TASKS: 'can_create_tasks',
  CAN_CREATE_JOBS: 'can_create_jobs',
  CAN_MANAGE_JOBS: 'can_manage_jobs',
  CAN_VIEW_JOB_LOGS: 'can_view_job_logs',
  CAN_READ_ROOT_CREDENTIALS: 'can_read_root_credentials',
  CAN_READ_AUDIT_LOGS: 'can_read_audit_logs',
  CAN_CREATE_HOSTED_RUNNER_JSON: 'can_create_hosted_runner_json',
  CAN_CREATE_HOSTED_RUNNER: 'can_create_hosted_runner',
  CAN_LIST_HOSTED_RUNNERS: 'can_list_hosted_runners',
  CAN_JSON_OVERRIDE: 'can_json_override',
  CAN_MANAGE_GITLAB_USERS: 'can_manage_gitlab_users',
  CAN_DESTROY_TENANT: 'can_destroy_tenant',
};

export const VALID_EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+(\.[^\s@]+)*[^.]$/;
export const VALID_URL_REGEX =
  /^(https?:\/\/)?(([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[a-zA-Z]{2,})(:\d+)?(\/[a-zA-Z0-9_.-]*)*(\?[a-zA-Z0-9=&_.-]*)?(#[a-zA-Z0-9=&_.-]*)?$/;

export const CONFIG_CHANGE_LIST_NAMES = {
  allowed_source_list_config: 'Allowed Source List configuration',
  custom_certificate: 'Custom Certificates',
  private_hosted_zones_config: 'Private Hosted Zones configuration',
  reverse_private_link_config: 'Outbound Private Link configuration',
  saml_config: 'SAML Configuration',
  byod_config: 'BYOD configuration',
  tenant: 'Tenant configuration',
};

export const AUDIT_LOG_QUERY_SCOPES = {
  ALL: { text: 'All', value: 'all' },
  TENANT: { text: 'Tenant', value: 'tenant' },
  AMP_JOB: { text: 'Amp Job', value: 'amp_job' },
  HOSTED_RUNNERS: { text: 'Hosted Runners', value: 'runners' },
};

export const AWS_REGION_NONE = 'None';

export const SWITCHBOARD_ENVS = {
  TEST: 'test',
  BETA: 'beta',
  PROD: 'production',
};

/*
  ^                    // Asserts the start of the string.
  [a-z0-9]+            // Matches one or more occurrences of any lowercase letter or digit.
  (                    // Start of a group that matches zero or more occurrences of the following pattern:
    [\-\.\u2013]       // Matches exactly one occurrence of either a hyphen, period, or en dash.
    [a-z0-9]+          // Matches one or more occurrences of any lowercase letter or digit.
  )*                   // End of the group. This allows for subdomains.
  \.                   // Matches a literal period character.
  [a-z]{2,6}           // Matches between 2 and 6 lowercase letters (TLD).
  (:[0-9]{1,5})?       // Optionally matches a colon followed by 1 to 5 digits (port number).
  $                    // Asserts the end of the string.
*/
export const VALID_HOSTNAME_REGEX = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?$/;

/*
1. ^(0|[1-9]\d*) - Matches the major version number, which is either '0' or a non-zero digit followed by any number of digits.
2. \. - Matches a literal dot.
3. (0|[1-9]\d*) - Matches the minor version number, following the same pattern as the major version.
4. \. - Matches a literal dot.
5. (0|[1-9]\d*) - Matches the patch version number, following the same pattern as the major and minor versions.
6. (?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))? - Matches the pre-release version if present. This part is optional (denoted by (?: ... )?).
  - (?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*) - Matches either '0', a non-zero number, or an identifier starting with a letter or hyphen followed by alphanumeric characters or hyphens.
  - (?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))* - Matches additional identifiers separated by dots.
7. (?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))? - Matches the build metadata if present. This part is also optional.
  - [0-9a-zA-Z-]+ - Matches alphanumeric characters or hyphens.
  - (?:\.[0-9a-zA-Z-]+)* - Matches additional segments of alphanumeric characters or hyphens separated by dots.
8. $ - Asserts the position at the end of the string.
*/
export const VALID_SEMVER_REGEX =
  /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;

export const VALID_VPCE_ID_REGEX = /^com.amazonaws.vpce.+/;

export const ONBOARDING_TENANTS_STATUS = {
  TENANT_CREATED: 'tenant_created',
  CONFIGURED: 'configured',
  DISPUTED: 'disputed',
  UNCONFIGURED: 'unconfigured',
  BYOK_CONFIGURED: 'byok_configured',
  BYOD_CONFIGURED: 'byod_configured',
  USER_CREATED: 'user_created',
};

export const ONBOARDING_TENANTS_QUERY_KEY = 'onboarding_tenants';

export const ROUTER_VARS = {
  ONBOARDING_TENANT_ID: 'onboardingTenantId',
  TENANT_ID: 'tenantId',
  CHANGE_LOG_ID: 'changeLogId',
  OPL_REGION_ID: 'oplRegionId',
  RUNNER_ID: 'runnerId',
};

export const SMTP_CONFIG_TLS_OPTIONS = {
  STARTTLS: 'starttls',
  TLS: 'tls',
  NONE: 'none',
};

export const RUNNER_OS_KEYS = {
  LINUX: 'linux',
};

export const RUNNER_OS = {
  [RUNNER_OS_KEYS.LINUX]: { label: 'Linux x84-64' },
};

export const RUNNER_INSTANCE_KEYS = {
  LINUX_SMALL_AMD64: 'linux-small-amd64',
  LINUX_MEDIUM_AMD64: 'linux-medium-amd64',
  LINUX_LARGE_AMD64: 'linux-large-amd64',
  LINUX_XLARGE_AMD64: 'linux-xlarge-amd64',
  LINUX_2XLARGE_AMD64: 'linux-2xlarge-amd64',
};

export const RUNNER_INSTANCE_TYPES = {
  [RUNNER_INSTANCE_KEYS.LINUX_SMALL_AMD64]: { label: 'Small', vcpu: 2, ram: 8 },
  [RUNNER_INSTANCE_KEYS.LINUX_MEDIUM_AMD64]: { label: 'Medium', vcpu: 4, ram: 16 },
  [RUNNER_INSTANCE_KEYS.LINUX_LARGE_AMD64]: { label: 'Large', vcpu: 8, ram: 32 },
  [RUNNER_INSTANCE_KEYS.LINUX_XLARGE_AMD64]: { label: 'X-Large', vcpu: 16, ram: 64 },
  [RUNNER_INSTANCE_KEYS.LINUX_2XLARGE_AMD64]: { label: '2X-Large', vcpu: 32, ram: 128 },
};

export const RUNNER_STATUS_KEYS = {
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  FAILED: 'failed',
};

export const RUNNER_STATUS = {
  [RUNNER_STATUS_KEYS.IN_PROGRESS]: { label: 'In progress', variant: 'info' },
  [RUNNER_STATUS_KEYS.COMPLETED]: { label: 'Completed', variant: 'success' },
  [RUNNER_STATUS_KEYS.FAILED]: { label: 'Failed', variant: 'danger' },
};

export const MAX_ALLOWED_OPL_LINKS_PER_TENANT = 10;
export const MAX_ALLOWED_OPL_LINKS_PER_REGION = 10;
export const MAX_ALLOWED_OPL_REGIONS = 2;

export const OPL_LINK_STATUS_KEYS = {
  UNAPPLIED: 'unapplied',
  ACTIVE: 'active',
  ERROR: 'error',
  DISABLED: 'disabled',
  PENDING_ACCEPTANCE: 'pending_acceptance',
};
export const OPL_LINK_STATUS_TEXT = {
  [OPL_LINK_STATUS_KEYS.UNAPPLIED]: 'Unapplied',
  [OPL_LINK_STATUS_KEYS.ACTIVE]: 'Connected',
  [OPL_LINK_STATUS_KEYS.ERROR]: 'Failed',
  [OPL_LINK_STATUS_KEYS.DISABLED]: 'Disabled',
  [OPL_LINK_STATUS_KEYS.PENDING_ACCEPTANCE]: 'Pending acceptance',
};
export const OPL_LINK_STATUS_VARIANT = {
  [OPL_LINK_STATUS_KEYS.UNAPPLIED]: 'warning',
  [OPL_LINK_STATUS_KEYS.ACTIVE]: 'success',
  [OPL_LINK_STATUS_KEYS.ERROR]: 'danger',
  [OPL_LINK_STATUS_KEYS.DISABLED]: 'muted',
  [OPL_LINK_STATUS_KEYS.PENDING_ACCEPTANCE]: 'info',
};

export const FEATURES = {
  DISABLE_OPL: 'disable_opl',
};
